// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
.btn-glass{
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    // border-color:rgba(255, 255, 255, 0.1);
    &:hover{        
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
    // border-color:rgba(255, 255, 255, 0.3);
    }
}



  .learning-nav-card{
    ol {
        list-style-type: none;
        // margin: 0;
        // padding: 0;
      }
      a{
          color:$gray-600
      }
 
        .active {a{   
                color:$primary !important
        }  }
  }